import { LisPublicMachine } from '../../types/machine.type';

export const LIS_MOCK_PUBLIC_MACHINE: LisPublicMachine = {
  id: '351-112891',
  imageUrl: 'assets/images/svg/machine-placeholder.svg',
  productHierarchyCategory: 'Glass cutting',
  machineType: 'BHK-60/33',
  serialNumber: '351-112891',
  startUpDate: '2021-04-02',
  warrantyStartDate: '2021-04-02',
  warrantyEndDate: '2022-04-02',
  sparePartsRelativePath: 'k_vari_top=141148&k_ver_top=571-072434',
  documents: [
    {
      id: 'DC1UY9L42TJ0V4#ba#351-112891#2021_01_19#ru.pdf',
      filename: 'DC1UY9L42TJ0V4#ba#351-112891#2021_01_19#ru.pdf',
      version: '2021-01-19',
      language: 'russisch',
      size: 5796246,
      category: 'OPERATING_MANUAL',
    },
    {
      id: 'DC1UY9DK2TJ0V4#ba#351-112891#2021_01_19#de.pdf',
      filename: 'DC1UY9DK2TJ0V4#ba#351-112891#2021_01_19#de.pdf',
      version: '2021-01-19',
      language: 'deutsch',
      size: 5761778,
      category: 'OPERATING_MANUAL',
    },
    {
      id: 'DC2MC8ZG2TJ0V4#ep#351-112891#2021_01_21.pdf',
      filename: 'DC2MC8ZG2TJ0V4#ep#351-112891#2021_01_21.pdf',
      version: '2021-01-21',
      language: null,
      size: 15746680,
      category: 'ELECTRICAL_PLAN',
    },
  ],
};
